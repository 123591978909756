.main-desk {
  width: 400px;
  max-width: 85%;
  display: flex;
  flex-direction: column;

  wired-card:first-child {
    margin-bottom: 25px;
  }
}
